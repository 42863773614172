import { Auth, Hub } from "aws-amplify";
import axios from "axios";
import "./configuration";
import { environmentsConfigs } from "./environments-configs";

const ENVIRONMENT =
  environmentsConfigs[process.env.ENVIRONMENT_ID || "MAIN"] ||
  environmentsConfigs.MAIN;

const loader = document.getElementById("loader");
const providers = document.getElementById("providers");
const error = document.getElementById("error");
const googleButton = document.getElementById("login-google-button");
const cleverButton = document.getElementById("login-clever-button");
const classLinkButton = document.getElementById("login-class-link-button");
const lilypadButton = document.getElementById("login-lilypad-button"); // Hiding for now; waiting roll out
const backButton = document.getElementById("back-button");
const ssoError = document.getElementById("sso-error");
const openAppButton = document.getElementById("open-app-button");
const linkToStoreButton = document.getElementById("link-to-store-button");
const infoSection = document.getElementById("info-section");

const getURLParameter = (sParam) => {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split("&");
  for (let i = 0; i < sURLVariables.length; i++) {
    const sParameterName = sURLVariables[i].split("=");
    if (sParameterName[0] === sParam) {
      return sParameterName[1];
    }
  }
};
const email = getURLParameter("email");
const provider = getURLParameter("provider");
const code = getURLParameter("code");
const errorDescription = getURLParameter("error_description");
const reLogin = getURLParameter("re_login");

/**
 * We expect the user to always select their IdP Account when this app opens.
 * The `userAlreadySignedIn` variable should controls this flow.
 * - If a `code` is present, it means the SSO provider has returned the flow to this page, so we consider the user as signed in.
 * - Otherwise, this value should be `false` so we can display the SSO buttons for our users and sign out any current authenticated user.
 */
let userAlreadySignedIn = false;
if (code) {
  console.debug("User attempted sign in already!");
  userAlreadySignedIn = true;
}

if (provider) {
  console.debug("Caching SSO Provider in session");
  sessionStorage.setItem("ctb_sso_provider", provider);
}
let userSsoProvider = sessionStorage.getItem("ctb_sso_provider", provider);

// Sign user out only if it isn't expected that it signed in already
if (!userAlreadySignedIn) {
  Auth.currentAuthenticatedUser()
    .then(() => {
      console.debug("Signing current user out");
      Auth.signOut();
    })
    .catch((e) => {
      console.debug("No user signed in, allowing log in");
      sessionStorage.setItem("authTrigger", "true");
      enableLogIn();
    });
}

const getMobileOS = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return "Android";
  }
  if (/CrOS/i.test(ua)) {
    return "Android";
  }
  if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  ) {
    return "iOS";
  }
  if (/Windows/.test(ua)) {
    return "Windows";
  }
  return "Other";
};

const accountLookupSSO = (email) =>
  axios.get(`${ENVIRONMENT.defaultBaseUrl}/account/lookup/sso`, {
    params: {
      email,
    },
  });

const checkEmail = async (email) => {
  try {
    const resp = await accountLookupSSO(email);

    loader.style.display = "none";
    if (resp.data.available && resp.data.existing_profiles) {
      providers.style.display = "unset";
      if (resp.data.existing_profiles.includes("Google")) {
        googleButton.style.display = "flex";
      }
      if (resp.data.existing_profiles.includes("clever")) {
        cleverButton.style.display = "flex";
      }
      if (resp.data.existing_profiles.includes("Classlink")) {
        classLinkButton.style.display = "flex";
      }
      if (resp.data.existing_profiles.includes("FrogStreet")) {
        lilypadButton.style.display = "flex";
      }
    } else {
      error.style.display = "unset";
    }
  } catch (e) {
    alert("Something went wrong, try again");
  }
};

const enableLogIn = async () => {
  const authTrigger = sessionStorage.getItem("authTrigger");

  if (!errorDescription && authTrigger && userSsoProvider) {
    setTimeout(() => {
      Auth.federatedSignIn({
        provider: userSsoProvider,
      });
    }, 1000);
  } else {
    if (!code && !errorDescription) {
      if (email) {
        checkEmail(email);
      } else {
        loader.style.display = "none";
        providers.style.display = "unset";
        googleButton.style.display = "flex";
        cleverButton.style.display = "flex";
        classLinkButton.style.display = "flex";
        lilypadButton.style.display = "flex";
      }
    }

    googleButton.addEventListener("click", () => {
      Auth.federatedSignIn({
        provider: "Google",
      });
      providers.style.display = "none";
      loader.style.display = "flex";
    });
    cleverButton.addEventListener("click", () => {
      Auth.federatedSignIn({
        provider: "clever",
      });
      providers.style.display = "none";
      loader.style.display = "flex";
    });
    classLinkButton.addEventListener("click", () => {
      Auth.federatedSignIn({
        provider: "Classlink",
      });
      providers.style.display = "none";
      loader.style.display = "flex";
    });
    lilypadButton.addEventListener("click", () => {
      Auth.federatedSignIn({
        provider: "FrogStreet",
      });
      providers.style.display = "none";
      loader.style.display = "flex";
    });
    backButton.addEventListener("click", () => {
      window.location = "/";
    });
  }
};

const PREDEFINED_ERROR_CODES = new Set([
  "CTB_REQUEST_SENT_ERROR",
  "CTB_REQUEST_DENIED_ERROR",
  "CTB_GENERIC_ERROR_MESSAGE",
]);
if (errorDescription) {
  loader.style.display = "none";
  const matches = errorDescription.match(/%22([^%22]*)%22/);
  const match = matches && matches.length > 1 ? matches[1] : undefined;
  const errorCode = PREDEFINED_ERROR_CODES.has(match) ? match : "error";

  ssoError.style.display = "unset";
  let foundError;
  if (errorCode !== "error") {
    foundError = document.getElementById(errorCode);
  } else {
    foundError = document.getElementById("unhandled-error");
    const description = document.getElementById("unhandled-error-description");
    const decodedErrorDescription = decodeURIComponent(errorDescription);
    description.textContent = decodedErrorDescription
      ? decodedErrorDescription.replace(/\+/g, " ")
      : "";
  }
  foundError.style.display = "unset";
}

function getAppLink(window, token, username) {
  console.debug(
    `Redirecting CTB App link based on OS: ${window.navigator.appVersion}`
  );
  if (window.navigator.appVersion.indexOf("Win") !== -1) {
    console.debug(
      "Opening the CTB App on Windows using a deeplink instead of a universal link."
    );
    return `cognitivetoybox://?q=RefreshToken=${token}ExpiresIn=3600Username=${username}`;
  }

  return `https://launch.cognitivetoybox.com?q=RefreshToken=${token}ExpiresIn=3600Username=${username}`;
}

linkToStoreButton.addEventListener("click", () => {
  const os = getMobileOS();
  if (os === "Windows") {
    window.open(
      "https://apps.microsoft.com/detail/9nprqn43ghrn?hl=en-US&gl=US"
    );
  } else if (os === "Android") {
    window.open(
      "https://play.google.com/store/apps/details?id=com.cognitivetoybox.ctbforschools&hl=en_US"
    );
  } else {
    window.open(
      "https://apps.apple.com/us/app/cognitive-toybox-for-schools/id1326330551"
    );
  }
});

Hub.listen("auth", ({ payload: { event, data } }) => {
  if (event === "signIn") {
    const authTrigger = sessionStorage.getItem("authTrigger");
    if (!authTrigger && reLogin && userSsoProvider) {
      sessionStorage.setItem("authTrigger", "true");
      return Auth.signOut();
    }
    const refreshToken = data.signInUserSession.refreshToken.token;
    const username = data.username;

    console.debug("Successful sign in, redirecting to App");
    sessionStorage.removeItem("authTrigger");

    const os = getMobileOS();
    if (userSsoProvider || os != "Windows") {
      window.location.replace(getAppLink(window, refreshToken, username));
    } else {
      loader.style.display = "none";
      infoSection.style.display = "flex";

      openAppButton.addEventListener("click", () => {
        window.open(
          `cognitivetoybox://?q=RefreshToken=${refreshToken}ExpiresIn=3600Username=${username}`,
          "_blank"
        );
      });
      window.open(getAppLink(window, refreshToken, username), "_blank");
    }
  }

  if (event === "oAuthSignOut" && userAlreadySignedIn === false) {
    console.debug("Finished sign out flow, enabling log in");
    enableLogIn();
  }

  if (event === "signIn_failure") {
    /*
     * Workaround to FrogStreet flow starting another sign in operation without finishing the previous one.
     * When user clicks on Log in with FS SSO Button on the app they will be redirected to
     * FS authorizer for authentication. FS auth doesn't return the user to our application as requested through the
     * callback_uri parameter, instead, they are redirecting to Lilypad.
     * The user then needs to click on Lilypad's launch link on their in-app browser executing a second sign in operation
     * when this application is waiting for the previous callback.
     */
    if (
      data instanceof Error &&
      userSsoProvider == "FrogStreet" &&
      !sessionStorage.getItem("sign_in_failure_raise")
    ) {
      console.log(`OAuth flow failed, re-trying ${userSsoProvider} more time.`);
      sessionStorage.setItem("sign_in_failure_raise", "true");
      Auth.federatedSignIn({
        provider: userSsoProvider,
      });
    } else if (
      code &&
      data.message == "invalid_grant" &&
      reLogin &&
      userSsoProvider
    ) {
      console.log(
        "Ignoring invalid grant exception in favor of a new sign in!"
      );
      sessionStorage.setItem("authTrigger", "true");
      Auth.currentAuthenticatedUser()
        .then(() => {
          console.debug("Signing current user out");
          Auth.signOut();
        })
        .catch((e) => {
          console.debug("No user signed in, allowing log in");
          enableLogIn();
        });
    } else {
      window.location.replace(`/?error_description=${data.message}`);
    }
  }
});
