import { Amplify } from "aws-amplify";
import { environmentsConfigs } from "./environments-configs";

const ENVIRONMENT =
  environmentsConfigs[process.env.ENVIRONMENT_ID || "MAIN"] ||
  environmentsConfigs.MAIN;

Amplify.configure({
  Auth: {
    region: ENVIRONMENT.region,
    userPoolId: ENVIRONMENT.userPoolId,
    userPoolWebClientId: ENVIRONMENT.appClientId,
    mandatorySignIn: false,
  },
  oauth: {
    domain: ENVIRONMENT.domain,
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    responseType: "code",
    scope: ["email", "aws.cognito.signin.user.admin", "profile", "openid"],
  },
});
