export const environmentsConfigs = {
  DEVELOPMENT: {
    service: "execute-api",
    region: "us-east-1",
    userPoolId: "us-east-1_KtUdSOcop",
    identityPoolId: "us-east-1:9b03aa68-74f2-4937-82a6-ff64212c6247",
    webPortalClientId: "12br6gluipps6o9012al07u3qt",
    appClientId: "13uptnf63q7efa2k87cq189v2",
    defaultBaseUrl:
      "https://l52brascf5.execute-api.us-east-1.amazonaws.com/dev",
    domain:
      "ctb-auth-developers.developers.cognitivetoybox.com",
    apiName: "DEVELOPMENT",
  },
  MAIN: {
    service: "execute-api",
    region: "us-east-1",
    userPoolId: "us-east-1_xDRUmu8Qd",
    identityPoolId: "us-east-1:c0fc205c-5309-4915-b730-e4d4d83f45ed",
    webPortalClientId: "212u7qa5r4of797jttatvupg8s",
    appClientId: "582h7sd40j8etniblbo4rc594",
    defaultBaseUrl:
      "https://uzos1f0z70.execute-api.us-east-1.amazonaws.com/web",
    domain: "ctb-auth.cognitivetoybox.com",
    apiName: "MAIN",
  },
  RIDE: {
    service: "execute-api",
    region: "us-east-1",
    userPoolId: "us-east-1_xDRUmu8Qd",
    identityPoolId: "us-east-1:c0fc205c-5309-4915-b730-e4d4d83f45ed",
    webPortalClientId: "212u7qa5r4of797jttatvupg8s",
    appClientId: "582h7sd40j8etniblbo4rc594",
    defaultBaseUrl:
      "https://dtw06779l4.execute-api.us-east-1.amazonaws.com/prod",
    apiName: "RIDE",
    domain: "ctb-auth.cognitivetoybox.com",
    cognitoGroup: "ride_users",
  },
};
